export const projectsData = [
  {
    id: 1,
    title: "Arkam",
    date: "Mai 2023",
    languages: ["React.JS", "Sass"],
    infos:
      "Arkam est une association musicale qui organise des évènements sur la métropole Lilloise. Ayant besoin d'un site internet pour mettre en avant leur collectif, j'ai été missionné pour m'en occuper.",
    img: "./assets/img/Vignette web I Arkam 04.jpg",
    link: "https://arkam-experience.fr/",
  },
  {
    id: 2,
    title: "Flight",
    date: "Novembre 2023",
    languages: ["React.JS", "Sass"],
    infos:
      "Flight propose la partie vitrine d'un site de réservation de vol créé par mes soins.",
    img: "./assets/img/Vignette web I Flight.jpg",
    link: "https://flight-lucasjules.fr/",
  },
  {
    id: 3,
    title: "Hoobank",
    date: "Janvier 2024",
    languages: ["React.JS", "Tailwind"],
    infos:
      "Voici la partie vitrine d'un site de banque nouvelle génération.",
    img: "./assets/img/Vignette web I Hoobank.jpg",
    link: "https://hoobank-lucasjules.fr/",
  },
  // {
  //   id: 4,
  //   title: "Creative Dev",
  //   date: "Juillet 2020",
  //   languages: ["Vue", "Php"],
  //   infos:
  //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quas cumque labore suscipit, pariatur laboriosam autem omnis saepe quisquam enim iste.",
  //   img: "./assets/img/projet-4.jpg",
  //   link: "http://www.google.com",
  // },
];