import React, {useRef} from "react";
import emailjs from '@emailjs/browser';

const ContactForm = () => {
    // const [name, setName] = useState("");
    // const [company, setCompany] = useState("");
    // const [phone, setPhone] = useState("");
    // const [email, setEmail] = useState("");
    // const [message, setMessage] = useState("");

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     let nameS = document.getElementById("name");
    //     let emailS = document.getElementById("email");
    //     let messageS = document.getElementById("message");
    //     let formMess = document.getElementById("form-message");

    //     const isEmail = () => {
    //         let isMail = document.getElementById("not-mail");
    //         let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    //         if (email.match(regex)) {
    //             isMail.style.display = "none";
    //             return true;
    //         } else {
    //             isMail.style.display = "block";
    //             isMail.style.animation = "dongle 1s";
    //             setTimeout(() => {
    //                 isMail.style.animation = "none";
    //             }, 1000);
    //             return false;
    //         }
    //     };

        // if (name && isEmail() && message) {
        //     const templateId = '';

        //     nameS.classList.remove("red");
        //     emailS.classList.remove("red");
        //     messageS.classList.remove("red");

        //     sendFeedback(templateId, {
        //         name,
        //         company,
        //         phone,
        //         email,
        //         message,
        //     });
        // } else {
        //     formMess.innerHTML = "Merci de remplir correctement les champs requis *";
        //     formMess.style.background = "rgb(253, 87, 87)";
        //     formMess.style.opacity = "1";

        //     if (!name) {
        //         nameS.classList.add("error");
        //     }
        //     if (!email) {
        //         emailS.classList.add("error");
        //     }
        //     if (!message) {
        //         messageS.classList.add("error");
        //     }
        // }
    // };

    // const sendFeedback = (templateId, variables) => {
    //     let formMess = document.getElementById("form-message");

    //     window.emailjs
    //         .send("gmail", templateId, variables)
    //         .then((res) => {
    //             formMess.innerHTML = "Message envoyé ! Nous vous recontacterons dès que possible.";
    //             formMess.style.background = "#00clec";
    //             formMess.style.opacity = "1";

    //             document.getElementById("name").classList.remove("error");
    //             document.getElementById("email").classList.remove("error");
    //             document.getElementById("message").classList.remove("error");
    //             setName("");
    //             setCompany("");
    //             setPhone("");
    //             setEmail("");
    //             setMessage("");

    //             setTimeout(() => {
    //                 formMess.style.opacity = "0";
    //             }, 5000);
    //         })
    //         .catch(
    //             (err) =>
    //             (formMess.innerHTML =
    //                 "Une erreur s'est produite, veuillez réessayer.")
    //         );
    // };

    // const form = React.useRef<any>(null);
    const form = useRef<any>();

    const sendEmail = (e:any) => {
        e.preventDefault();
        // const { current } = form;

        let formMess = document.getElementById("form-message");

        emailjs
            .sendForm(
                'service_2xuynkk',
                'template_2vct25s', 
                form.current,
                '65cpTbJonagAtRqck')
        .then((result) => {
            console.log(result.text);
            console.log("message sent");
            formMess!.innerHTML = "Message envoyé ! Nous vous recontacterons dès que possible.";
            formMess!.style.background = "#00clec";
            formMess!.style.opacity = "1";
            setTimeout(() => {
            formMess!.style.opacity = "0";
            }, 5000);
        }, (error) => {
            console.log(error.text);
            console.log("message not sent");
            formMess!.innerHTML = "Une erreur s'est produite, veuillez réessayer."
        });
    };

    return (

        <form  className="contact-form" ref={form} onSubmit={sendEmail}>
            <h2>contactez-moi</h2>
            <div className="form-content">
                <input
                    type="text"
                    id="name"
                    name="user_name"
                    required
                    placeholder="name *"
                />
                <input
                    type="text"
                    id="company"
                    name="company"
                    required
                    placeholder="company"
                />
                <input
                    type="text"
                    id="phone"
                    name="phone"
                    placeholder="phone"
                />
                <div className="email-content">
                    <label id="not-mail">Email non valide</label>
                    <input type="email"
                        id="email"
                        name="user_email"
                        required
                        placeholder="email *"
                    />
                </div>
                <textarea
                    id="message"
                    name="message"
                    placeholder="message *"
                    required
                />
            </div>
            <input
            className="button hover"
            type="submit"
            value="send"
            />
            <div id="form-message"></div>
        </form>
    );
};

export default ContactForm;