import React, { useEffect, useState } from 'react'
import Project from './Project';

function Slider({maxSize}:any) {
    const [currentIndex, setCurrentIndex] = useState(0)

    // const handleNext = () => {
    //     setCurrentIndex((index) => {
    //         if (index === maxSize -1) {
    //             return 0;
    //         } else {
    //             return index +1;
    //         }
    //     });
    // };

    // const handlePrevious = () => {
    //     setCurrentIndex((index) => {
    //         if (index < 1) {
    //             return maxSize -1
    //         } else {
    //             return index -1;
    //         }
    //     })
    // };

    const handleNext = () => {
        setCurrentIndex((index) => (index === maxSize - 1 ? 0 : index + 1));
      };
    
      const handlePrevious = () => {
        setCurrentIndex((index) => (index === 0 ? maxSize - 1 : index - 1));
      };

    // useEffect(() => {
    //     const slider = document.querySelector('.project-main') as HTMLElement;
    //     slider.style.transform = `translateX(100%)`;
    //   }, [currentIndex]);

    return (
        <div className='carousel'>
            <Project projectNumber={currentIndex} />
            <div className="scroll-bottom">
                <div className="sb-main">
                    <span onClick={handlePrevious}>&#10092;</span>

                    <p className="center">scroll</p>
                
                    <span onClick={handleNext}>&#10093;</span>
                </div>
            </div>
        </div>
    )
}

export default Slider