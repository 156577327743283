import React from 'react';
import DynamicText from '../components/DynamicText';
import Navigation from '../components/Navigation';
import SocialNetwork from '../components/SocialNetwork';
import Slider from '../components/Slider';
import { projectsData } from '../data/projectsData';
import ContactForm from '../components/ContactForm';
import { Reveal } from '../components/Reveal';

const Home = () => {


    return (
        <main>
            {/* <div className='background-img'>
                <img src="./assets/img/Background-04.jpg" alt="" />
            </div> */}
            <section id="home">
                
                <Navigation />

                <SocialNetwork />

                <div className="ring-relative"></div>

                <div className="home-main">
                    <div className="main-content">
                        <h1>LUCAS JULES</h1>
                        <h2><DynamicText /></h2>
                    </div>
                </div>

                <Reveal>
                <div className='btn-down'>
                    <a href='#about'>
                        <div className='button-down'>
                            <img src="assets\img\down-arrow.png" alt="icon" className='icon' />
                        </div>
                        {/* <p>Scroll Down</p> */}
                    </a>
                </div>
                </Reveal>
            </section>


            <section id="about">

                <div className="ring-relative"></div>

                <div className='ligne-1'>
                    <hr />
                </div>

                <div className='about-infos'>
                    {/* <Reveal> */}
                    <div className='my-img'>
                        <img src="./assets/img/moi-zoom.jpg" alt="img" />
                    </div>
                    {/* </Reveal> */}
                    
                    <div className="text-infos">
                        <Reveal>
                        <div className='title'>
                            <p>Get To Know More</p>
                            <h2>About Me</h2>
                        </div>
                        </Reveal>

                        <Reveal>
                        <div className="experiences">
                            <div className="working-years">
                                <div className='icon'>
                                    <img src="assets\img\briefcase-solid.svg" alt="" />
                                </div>
                                <h3>Experiences</h3>
                                <p>2+ years</p>
                                <p>Frontend Development</p>
                            </div>
                            <div className="education">
                                <div className='icon'>
                                    <img src="assets\img\school-solid.svg" alt="" />
                                </div>
                                <h3>Education</h3>
                                <p>BTS : SN (Systèmes Numériques)</p>
                                <p>M2i Formation : Développeur Web</p>
                            </div>
                        </div>
                        </Reveal>

                        <Reveal>
                        <div className="more">
                            <p>Après de nombreuses années à travailler dans divers domaines aussi passionnants que différents, j'ai repris mes études d'informatique car le numérique a toujours fait partie de ma vie.
                            J'ai donc continué mon cursus afin de me mettre à jour dans mes connaissances. Passionné et motivé avant tout, je réalise des "side-projects" pour monter en compétence et apprendre de nouvelles pratiques.
                            </p>
                            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi, corrupti? Beatae nesciunt voluptas, reprehenderit adipisci soluta explicabo incidunt repudiandae porro impedit, dolorem quis officia ipsum nihil aperiam consequuntur neque. Nulla?</p> */}
                        </div>
                        </Reveal>
                    </div>
                </div>

                <div className='ligne-2'>
                    <hr />
                </div>
                
                <Reveal>
                <div className='btn-down'>
                    <a href='#experiences'>
                        <div className='button-down'>
                            <img src="assets\img\down-arrow.png" alt="icon" className='icon' />
                        </div>
                        {/* <p>Scroll Down</p> */}
                    </a>
                </div>
                </Reveal>
            </section>

            <section id="experiences">

                <div className="ring-relative"></div>

                <div className="my-experience">
                    <div className="box-title">
                        <div className='ligne-2'>
                            <hr />
                        </div>  
                        <Reveal>
                        <div className="text-title">
                            <p>Explore My</p>
                        </div>
                        </Reveal>

                        <Reveal>
                        <div className="title">
                            <h2>Experience</h2>
                        </div>
                        </Reveal>
                    </div>

                    <Reveal>
                    <div className='text-exp'>
                        <p>Aimant créer des choses soit avec un crayon, soit avec un clavier, j'adore voir mon imagination s'animer sous les clics, autant que résoudre des casses-têtes exigeants.</p>
                        <p>Voici les technologies avec lesquelles j'aime particulièrement travailler en ce moment.</p>
                    </div>
                    </Reveal>

                    <Reveal>
                    <div className="infos-exp">
                        <div className="front-exp">
                            <div className="title-front">
                                <h3>Frontend Development</h3>
                            </div>

                            <div className="infos-front">
                                <div className="one">
                                    <i className="fa-regular fa-circle-check"></i>
                                    <div className='good'>
                                        <h4>SASS</h4>
                                        <p>Good</p>
                                    </div>
                                </div>
                                <div className="two">
                                    <i className="fa-regular fa-circle-check"></i>
                                    <div className="good">
                                        <h4>Bootstrap</h4>
                                        <p>Good</p>
                                    </div>
                                </div>
                                <div className="three">
                                    <i className="fa-regular fa-circle-check"></i>
                                    <div className="good">
                                    <h4>JavaScript</h4>
                                        <p>Good</p>
                                    </div>
                                </div>
                                <div className="four">
                                    <i className="fa-regular fa-circle-check"></i>
                                    <div className="good">
                                    <h4>React.JS</h4>
                                        <p>Good</p>
                                    </div>
                                </div>
                                <div className="five">
                                    <i className="fa-regular fa-circle-check"></i>
                                    <div className="good">
                                        <h4>Wordpress</h4>
                                        <p>Good</p>
                                    </div>
                                </div>
                                <div className="six">
                                    <i className="fa-regular fa-circle-check"></i>
                                    <div className="good">
                                        <h4>TypeScript</h4>
                                        <p>Good</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="back-exp">
                            <div className="title-back">
                                <h3>Backend Development</h3>
                            </div>

                            <div className="infos-back">
                                <div className="one-back">
                                    <i className="fa-regular fa-circle-check"></i>
                                    <div className="good">
                                        <h4>PHP</h4>
                                        <p>Good</p>
                                    </div>
                                </div>
                                <div className="two-back">
                                    <i className="fa-regular fa-circle-check"></i>
                                    <div className="good">
                                        <h4>Wordpress</h4>
                                        <p>Good</p>
                                    </div>
                                </div>
                                <div className="three-back">
                                    <i className="fa-regular fa-circle-check"></i>
                                    <div className="good">
                                        <h4>MySQL</h4>
                                        <p>Good</p>
                                    </div>
                                </div>
                            </div>
                            <p className='everything'>Everything is good</p>
                        </div>
                    </div>                   
                    </Reveal>
                    <Reveal>
                    <div className="resume">
                        
                        <h3>
                            To check out my resume click here !
                        </h3>
                        <a href="./../assets/img/LUCAS-JULES.pdf" download="Resume-LucasJules"><img src="./assets/img/folder.png" alt="folder" /></a>
                    </div>
                    </Reveal>
                </div>

                <Reveal>
                <div className='btn-down'>
                    <a href='#projects'>
                        <div className='button-down'>
                            <img src="assets\img\down-arrow.png" alt="icon" className='icon' />
                        </div>
                        {/* <p>Scroll Down</p> */}
                    </a>
                </div>
                </Reveal>
            </section>

            <section id="projects">

                <div className="ring-relative"></div>

                <div className='ligne-1'>
                    <hr />
                </div>

                <div className="projects-slide">
                    <Reveal>
                    <Slider maxSize={projectsData.length}/>
                    </Reveal>
                </div>

                <div className='ligne-2'>
                    <hr />
                </div>

                <Reveal>
                <div className='btn-down'>
                    <a href='#contact'>
                        <div className='button-down'>
                            <img src="assets\img\down-arrow.png" alt="icon" className='icon' />
                        </div>
                        {/* <p>Scroll Down</p> */}
                    </a>
                </div>
                </Reveal>
            </section>
            

            <section id="contact">
                <Reveal>
                    <div className='knowmore'>
                        <h1 className='sentence'>Envie d'en savoir plus ? Envoyez-moi quelques lignes et parlons-en<img src="assets/img/coffee-cup.png" alt="coffee" />.</h1>
                        {/* <img src="assets/img/coffee-cup.png" alt="coffee" /> */}
                        {/* <h1 className='dot'>.</h1> */}
                    </div>
                </Reveal>
                
                <Reveal>
                <ContactForm />
                </Reveal>

                <div className="circle-relative"></div>
                    <div className="contact-infos">
                        <div className="address">
                            <div className="content">
                                <h4>adresse</h4>
                                <p>291 boulevard Victor Hugo</p>
                                <p>59000 Lille</p>
                            </div>
                        </div>

                        <div className="phone">
                            <div className="content">
                                <h4>téléphone</h4>
                                <p>06 31 89 45 66</p>
                            </div>
                        </div>
                        <div className="email">
                            <div className="content">
                                <h4>email</h4>
                                <p>lucasju@hotmail.fr</p>
                                <p>lucasjules-dev@outlook.fr</p>
                            </div>
                        </div>
                    </div>
                <SocialNetwork />

                <div className='btn-up'>
                    <a href='#'>
                        <div className='button-up'>
                            <img src="assets\img\up-arrow.png" alt="icon" className='icon' />
                        </div>
                    </a>
                </div>

            </section>
        </main>
    );
};

export default Home;