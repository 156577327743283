import React, { useState } from 'react';

const Logo = () => {

    const [flex, setFlex] = useState(false)
    const changeFlex = () => {
        if (window.scrollY >= 90) {
            setFlex(true)
        } else {
            setFlex(false)
        }
    }

    window.addEventListener('scroll', changeFlex)
    
    return (
        <span className={flex ? "logo logo-flex" : "logo"}>LJ</span>
    );
};

export default Logo;