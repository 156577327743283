import React, { useState } from 'react';
import { projectsData } from '../data/projectsData';

const Project = (props:any) => {
    const [currentProject] = useState(projectsData);
    const project = currentProject[props.projectNumber];

    // random background circle
    // let left = Math.floor(Math.random() * 200 + 700) + "px";
    // let top = Math.floor(Math.random() * 200 + 150) + "px";
    // let transform = "scale(" + (Math.random() + 0.7) + ")";  // math.random vaut un chiffre entre 0 et 1 soit ici, 0.7 au minimum et 1.7 au maximum


    return (
        <div className="project-main">
            <div className="project-content">
                <div>
                    <h1>PROJECTS</h1>
                    <h2>{project.title}</h2>
                </div>
                <p>{project.date}</p>
                <ul className="languages">
                    {project.languages.map((item) => {
                        return <li key={item}>{item}</li>
                    })}
                </ul>
            </div>
            <div className="img-content">
                <div className="img-container hover">
                    <a href={project.link} target="_blank">
                        <span>
                            <h3>{project.title}</h3>
                            <p>{project.infos}</p>
                        </span>
                        <img src={project.img} alt={project.title} />
                    </a>
                </div>
                <div className="button-container">
                    <a href={project.link} target="_blank" rel="nooperner noreferrer" className="hover">
                        <span className="button">voir le site</span>
                    </a>
                </div>
            </div>
            {/* <span class="random-circle" style={{left, top, transform}}></span> */}
        </div>
    );
};

export default Project;