import React, { useState } from 'react';
import Logo from './Logo';
import { Reveal } from './Reveal';

const Navigation = () => {

    const [flex, setFlex] = useState(false)
    const changeFlex = () => {
        if (window.scrollY >= 90) {
            setFlex(true)
        } else {
            setFlex(false)
        }
    }

    window.addEventListener('scroll', changeFlex)

    return (
        <div className={flex ? "navigation navigation-flex" : "navigation"}>
            <a href="#" className={flex ? "a a-flex" : "a"}><Logo></Logo></a>
            <ul className={flex ? "links-flex" : "links"}>
                <Reveal>
                    <a href="#about"><li className={flex ? "lis-flex" : "lis"}>about</li></a>
                </Reveal>
                <Reveal>
                    <a href="#experiences"><li className={flex ? "lis-flex" : "lis"}>experiences</li></a>
                </Reveal>
                <Reveal>
                    <a href="#projects"><li className={flex ? "lis-flex" : "lis"}>projects</li></a>
                </Reveal>
                <Reveal>
                    <a href="#contact"><li className={flex ? "lis-flex" : "lis"}>contact</li></a>
                </Reveal>
            </ul>
        </div>
    );
};

export default Navigation;