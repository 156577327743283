import React from 'react';
import { Reveal } from './Reveal';

const SocialNetwork = () => {

    const anim = () => {
        let navLinks = document.querySelectorAll<any>('.social-network a');

        navLinks.forEach(link => {
            link.addEventListener('mouseover', (e:any) => {
                let attrX = e.offsetX - 20;
                let attrY = e.offsetY - 13;

                link.style.transform = `translate(${attrX}px, ${attrY}px)`;
            })
            link.addEventListener('mouseleave', () => {
                link.style.transform = ''; 
            })
        })
    }
    return (
        
        <div className="social-network">
            <ul className="content">

                <a href="https://www.linkedin.com/in/lucas-jules-5873a5177/" target="_blank" rel="noopener noreferrer" className="hover" onMouseOver={anim}>
                <Reveal>
                    <li><i className="fab fa-linkedin"></i></li>
                </Reveal>
                </a>

                <a href="https://github.com/Mckennn" target="_blank" rel="noopener noreferrer" className="hover" onMouseOver={anim}>
                <Reveal>
                    <li><i className="fab fa-github"></i></li>
                </Reveal>
                </a>

                {/* <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="hover" onMouseOver={anim}>
                <Reveal>
                    <li><i className="fab fa-instagram"></i></li>
                </Reveal>
                </a> */}
            </ul>
        </div>
    );
};

export default SocialNetwork;